import $ from "jquery";

const showAlert = (message) => {
  let notify = $.notify(
    { message: message },
    {
      type: "alert",
      allow_dismiss: true,
      placement: {
        align:"center"
      },
      animate: {
        enter: "animated bounce",
        exit: "animated bounce"
      }
    }
  );
  $(document).one("turbo:before-visit", () => notify.close());
  $(document).one("turbo:before-cache", () => notify.$ele.remove());
};

// set it as window object
const nobi = window.nobi || {};
nobi.showAlert = showAlert;

// export as function
export default showAlert;

